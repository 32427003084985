<template>
  <component
    :is="tag"
    :class="[textColor, typography]"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'BaseTypology',
  props: {
    variant: {
      type: String,
      validator(val) {
        return ['small', 'intro', 'bold', 'margin-0'].includes(val);
      },
      default: null,
    },
    color: {
      type: String,
      validator(val) {
        return ['white', 'gray', 'light-gray', 'green', 'light-green'].includes(val);
      },
      default: null,
    },
    tag: {
      type: String,
      default: 'span',
    },
  },
  computed: {
    // translates variant to aab-typography values, defaults to passed variant
    typography() {
      if (this.variant === 'small') {
        return 'text-small';
      }
      if (this.variant === 'intro') {
        return 'text-intro';
      }
      if (this.tag === 'label') {
        return 'label';
      }
      return this.variant;
    },
    // translates values to aab-typography values, defaults to passed color
    textColor() {
      if (this.color === 'white') {
        return 'text-white';
      }
      if (this.color === 'green') {
        return 'text-green';
      }
      if (this.color === 'gray') {
        return 'text-gray';
      }
      if (this.color === 'light-gray') {
        return 'text-light-gray';
      }
      return this.color;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.p;
@include lib.label;
@include lib.h4;
@include lib.text_intro;
@include lib.text_small;
@include lib.text_green;
@include lib.text_gray;
@include lib.text_light_gray;
@include lib.text_white;

.light-green {
  color: lib.$g200;
}

.margin-0 {
  margin: 0;
}

.bold {
  font-weight: 700;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: 0.1s color;
  color: lib.$info300;

  &:hover {
    text-decoration: underline;
  }
}
</style>
