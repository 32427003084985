<template>
  <base-data
    id="base-data-sid-request-list-page"
    :rows="sidRequests"
    :columns="columns"
    :title="`${$tc('gen.request', 2)} (${totalItems})`"
    selectable-rows
    :loading="loading"
    :error="error"
    :empty-message="$t('shareholderId.noResults',
                       { data: $tc('shareholderId.response', 2).toLowerCase() }
    )"
    :sort-on="sortOn"
    :sort-direction="sortDirection"
    :action-bar="actionBar"
    :total-items="totalItems"
    :page-size="pageSize"
    :page-number="pageNumber"
    @request-update="updateSidRequestList($event)"
    @row-selected="onTileClick($event)"
    @action-bar-event="processActionBarEvent($event)"
  />
  <div>
    <base-filter-side-panel
      :key="refreshPanel"
      :open-panel="openFiltersPanel"
      @close-panel="onCancelFilters()"
      @apply-filters="onApplyFilters()"
      @select-filter="setSelectedFilters($event)"
    >
      <template #filter-panel-body>
        <div
          id="filter-statuses"
        >
          <base-typology
            tag="h5"
            class="mb-1 mt-0"
            color="green"
          >
            {{ $t('utils.status') }}
          </base-typology>
          <aab-checkbox
            v-for="(value, key) in statuses"
            :key="key"
          >
            <input
              :id="`quality-${key}`"
              slot="input-slot"
              type="checkbox"
              :checked="filters.status?.includes(value)"
              :value="value"
              @change="setSelectedFilters(value)"
            >
            <label
              slot="label-slot"
              :for="`quality-${key}`"
            >
              {{ $t('utils.statuses.' + key) }}
            </label>
          </aab-checkbox>
        </div>
      </template>
    </base-filter-side-panel>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { getSidRequests } from '@/services/sidService';
import { generalStatus } from '@/utils/reference/globalConstants';
import { getIssuers } from '@/services/issuerService';
import BaseData from '@/components/base/BaseData.vue';
import navItems from '@/utils/reference/navItems';
import { formatDateCountdown } from '@/utils/formatLib';
import BaseFilterSidePanel from '@/components/base/BaseFilterSidePanel.vue';
import BaseTypology from '@/components/base/BaseTypology.vue';

export default defineComponent({
  name: 'RequestListing',
  components: {
    BaseTypology,
    BaseFilterSidePanel,
    BaseData,
  },
  data() {
    return {
      loading: false,
      sidRequests: [],
      issuers: [],
      pageSize: 25,
      pageNumber: 1,
      statuses: generalStatus,
      totalItems: 0,
      maxDaysRemaining: 7,
      error: null,
      searchBy: null,
      sortOn: 'disclosureDeadlineDate',
      sortDirection: 'DESC',
      openFiltersPanel: false,
      refreshPanel: 0,
      temporarySelectedFilters: [],
      filters: {
        status: [generalStatus.inProgress],
      },
    };
  },
  computed: {
    actionBar() {
      return {
        primaryAction: {
          text: this.$t('shareholderId.newRequest'),
          icon: 'sy_shapes_plus_large',
          event: 'newRequest',
        },
        search: {
          type: 'inputDropdown',
          searchOptionsKey: 'shareholderId.matchingSearchOptions',
          searchData: this.issuers,
          event: 'search',
        },
        filters: {
          setFilters: true,
          text: `${this.$t('utils.filters')} (${this.filters.status.length})`,
        },
      };
    },
    columns() {
      return [
        {
          key: 'issuerName',
          label: this.$t('issuer.issuer'),
          template: 'title',
          width: '30',
        },
        {
          key: 'recordDate',
          label: this.$t('shareholderId.recordDate'),
          sortable: true,
          width: '15',
        },
        {
          key: 'status',
          template: 'status',
          label: this.$t('utils.status'),
          sortable: true,
          width: '25',
        },
        {
          key: 'disclosureDeadlineDate',
          label: this.$t('shareholderId.disclosureDeadlineDate'),
          sortable: true,
          width: '20',
        },
        {
          key: 'responseRate',
          template: 'progressBar',
          label: this.$t('shareholderId.responseRate'),
          width: '20',
        },
        {
          key: 'reportDeadlineDate',
          label: this.$t('shareholderId.reportDeadlineDate'),
          sortable: true,
          width: '25',
        },
      ];
    },
  },
  created() {
    this.getSidRequests();
    this.getIssuers();
    this.setTemporarySelectedFilters();
  },
  methods: {
    async getSidRequests() {
      this.loading = true;
      this.sidRequests = [];
      this.error = null;
      try {
        const data = await getSidRequests(
          this.pageNumber,
          this.pageSize,
          this.filters,
          this.searchBy,
          this.sortOn,
          this.sortDirection,
        );
        this.sidRequests = data.sidRequests;
        this.totalItems = data.totalItems;
        this.dateCountDown();
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.getSidRequests')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
    async getIssuers() {
      this.issuers = [];
      try {
        const data = await getIssuers(
          null,
          null,
          true,
          'GM',
          null,
        );
        this.issuers = Object.keys(data.issuers)
          .map((key) => ({
            id: data.issuers[key].uuid,
            name: data.issuers[key].name,
          }));
      } catch (e) {
        this.error = `${this.$t('issuer.errors.getIssuers')} ${e.code}: ${this.$t(e.messageKey)}`;
      }
    },
    dateCountDown() {
      this.sidRequests.forEach((request, index) => {
        const unknownDate = this.$t('days.unknown');
        if (this.sidRequests[index].disclosureDeadlineDate !== unknownDate) {
          this.sidRequests[index].disclosureDeadlineDate = formatDateCountdown(
            request.disclosureDeadlineDate,
            this.maxDaysRemaining,
          );
        }
        if (this.sidRequests[index].reportDeadlineDate !== unknownDate) {
          this.sidRequests[index].reportDeadlineDate = formatDateCountdown(
            request.reportDeadlineDate,
            this.maxDaysRemaining,
          );
        }
      });
    },
    async processActionBarEvent(event) {
      switch (event.event) {
        case 'newRequest':
          this.$router.push(navItems.sidHome.create.route);
          break;
        case 'search':
          this.pageNumber = 1;
          this.searchBy = event.value;
          await this.getSidRequests();
          break;
        case 'open-filters':
          this.openFiltersPanel = !this.openFiltersPanel;
          break;
        default: console.warn('Unknown action bar event');
      }
    },
    setTemporarySelectedFilters() {
      this.temporarySelectedFilters = [...this.filters.status];
    },
    async updateSidRequestList(event) {
      this.pageSize = event.pageSize ? event.pageSize : this.pageSize;
      this.pageNumber = event.pageNumber ? event.pageNumber : this.pageNumber;
      this.sortOn = event.sortOn ? event.sortOn : this.sortOn;
      this.sortDirection = event.sortDir ? event.sortDir : this.sortDirection;
      await this.getSidRequests();
    },
    async onCancelFilters() {
      if (this.openFiltersPanel) {
        this.temporarySelectedFilters = [...this.filters.status];
        this.openFiltersPanel = false;
        // This is based on animation speed to refresh the panel after closing it
        setTimeout(() => {
          this.refreshPanel += 1;
        }, 300);
      }
    },
    async onApplyFilters() {
      this.openFiltersPanel = false;
      this.filters.status = [...this.temporarySelectedFilters];
      await this.getSidRequests();
    },
    setSelectedFilters(value) {
      if (this.temporarySelectedFilters.includes(value)) {
        this.temporarySelectedFilters = this.temporarySelectedFilters.filter((s) => s !== value);
      } else {
        this.temporarySelectedFilters.push(value);
      }
    },
    onTileClick(event) {
      this.$router.push({
        name: navItems.sidHome.sidRequest.details.routeName,
        params: { sidRequestUuid: event.rowId },
      });
    },
  },
});
</script>
