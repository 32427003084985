import axios from 'axios';
import createError from '@/utils/errors';
import {
  createDisclosureDetailsData,
  createSidRequestData,
  createSidRequestDetails,
  createSidRequestShareholders,
} from '@/utils/serviceHelpers/sidRequestHelpers';
import {
  createAggregatedNomineeData,
  createMatchingDetailsData,
  createNomineeAccountPerAccountServicerData,
  createNomineeAccountData,
  createResponseAccountData,
} from '@/utils/serviceHelpers/sidReconHelpers';
import constructQueryParams from '@/utils/serviceHelpers/genericHelpers';

/**
 * Get the SID Requests and their attributes from database
 * @name getSidRequests
 * @param pageNumber   the page number
 * @param pageSize     the page size
 * @param statuses       filter on the status
 * @param searchBy     filter on issuer name or ISIN
 * @param sortBy           the key by which to sort
 * @param sortDirection    the direction in which to sort
 * @returns {Promise}  List of SID Requests, formatted
 */
export async function getSidRequests(
  pageNumber,
  pageSize,
  statuses,
  searchBy,
  sortBy,
  sortDirection,
) {
  const queryString = constructQueryParams({
    pageNumber, pageSize, searchBy, sortBy, sortDirection,
  }, statuses);
  return axios.get(`v1/sid/sid-requests${queryString}`)
    .then((resp) => createSidRequestData(resp.data))
    .catch((error) => createError(error));
}

/**
 * Get the SID Request details
 * @name getSidRequestDetails
 * @param sidRequestUuid   this uuid of the sid request
 * @returns {Promise}      SID Request Details, formatted
 */
export async function getSidRequestDetails(sidRequestUuid) {
  return axios.get(`v1/sid/sid-requests/${sidRequestUuid}`)
    .then((resp) => createSidRequestDetails(resp.data))
    .catch((error) => createError(error));
}

/**
 * Create a new SID request.
 * If second parameter is false, SID request goes to awaiting issuer approval.
 * If parameter is true, the SID request is set to draft.
 * @name createSidRequest
 * @param sidRequest   this uuid of the sid request
 * @param draft   this uuid of the sid request
 * @returns {Promise}      SID Request Details, formatted
 */
export async function createSidRequest(sidRequest, draft) {
  let url = '/v1/sid/sid-requests';
  if (draft) {
    url = url.concat('?draft=true');
  }
  return axios.post(url, sidRequest)
    .then((resp) => resp)
    .catch((error) => createError(error));
}

/**
 * Get the SID Request Disclosure Progress
 * @name getDisclosureProgressData
 * @param sidRequestUuid   this uuid of the sid request
 * @returns {Promise}      SID Request Progress details
 */
export async function getDisclosureProgressData(
  sidRequestUuid,
) {
  return axios.get(`v1/sid/sid-requests/${sidRequestUuid}/progress`)
    .then((resp) => (resp.data))
    .catch((error) => createError(error));
}

/**
 * Get the SID Request disclosures per country
 * @name getDisclosuresPerCountry
 * @param sidRequestUuid   this uuid of the sid request
 * @returns {Promise}      SID Request Disclosures per country
 */
export async function getDisclosuresPerCountry(
  sidRequestUuid,
) {
  return axios.get(`v1/sid/sid-requests/${sidRequestUuid}/disclosures-by-country`)
    .then((resp) => (resp.data))
    .catch((error) => createError(error));
}

/**
 * Get the SID Request shareholders
 * @name getSidRequestShareholders
 * @param sidRequestUuid   this uuid of the sid request
 * @param pageNumber       the page number
 * @param pageSize         the page size
 * @param filters          additional filters
 * @returns {Promise}      SID Request Details, formatted
 */
export async function getSidRequestShareholders(
  sidRequestUuid,
  pageNumber,
  pageSize,
  filters,
) {
  const queryString = constructQueryParams({ pageNumber, pageSize }, filters);
  return axios.get(`v1/sid/sid-requests/${sidRequestUuid}/shareholders${queryString}`)
    .then((resp) => createSidRequestShareholders(resp.data))
    .catch((error) => createError(error));
}

/**
 * Get the disclosure details of one particular shareholder
 * @name getDisclosureDetails
 * @param sidRequestUuid    The uuid of the sid request
 * @param disclosureId      The id of the disclosure
 * @returns {Promise}       The disclosure details, formatted
 */
export async function getDisclosureDetails(
  sidRequestUuid,
  disclosureId,
) {
  return axios.get(`v1/sid/sid-requests/${sidRequestUuid}/disclosures/${disclosureId}`)
    .then((resp) => createDisclosureDetailsData(resp.data))
    .catch((error) => createError(error));
}

export async function download(fileUrl, elementCreator) {
  const response = await axios.get(fileUrl, {
    responseType: 'blob',
  });
  const contentDisposition = response.headers['content-disposition'];
  const url = window.URL.createObjectURL(new Blob([response.data]));
  let filename = contentDisposition.split(';')[1].split('=')[1].trim();
  filename = filename.substring(1, filename.length - 1);
  const link = elementCreator.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
}

export async function getExcelReport(sidRequestUuid, resolve, reject, elementCreator = document) {
  const response = await axios.post('/v2/sid/agent/sid-reports-async/jobs', {
    sidRequestId: sidRequestUuid,
  });
  const { location } = response.headers;
  const interval = setInterval(async () => {
    const intervalResponse = await axios.get(location);
    if (intervalResponse.status > 300) {
      clearInterval(interval);
      reject();
    }
    if (intervalResponse.data === 'DONE') {
      clearInterval(interval);
      await download(intervalResponse.headers.location, elementCreator);
      resolve();
    }
  }, 1000);
  return interval;
}

/**
 * Get the aggregated nominee data
 * @name getAggregatedNominees
 * @param sidRequestUuid   this uuid of the sid request
 * @param pageNumber       the page number
 * @param pageSize         the page size
 * @param sortBy           the key by which to sort
 * @param sortDirection    the direction in which to sort
 * @param filters          the filters
 * @returns {Promise}      Aggregated nominee data, formatted
 */
export async function getAggregatedNominees(
  sidRequestUuid,
  pageNumber,
  pageSize,
  sortBy,
  sortDirection,
  filters,
) {
  const queryString = constructQueryParams({
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
  }, filters);
  return axios.get(`v1/sid/sid-requests/${sidRequestUuid}/nominees${queryString}`)
    .then((resp) => createAggregatedNomineeData(resp.data))
    .catch((error) => createError(error));
}

/**
 * Get the nominee account data aggregated per account servicer
 * @name getAccountServicerLevelNominees
 * @param nomiUuid         the aggregate nominee uuid
 * @returns {Promise}      Aggregated nominee data, formatted
 */
export async function getAccountServicerLevelNominees(nomiUuid) {
  return axios.get(`v1/sid/nominees/${nomiUuid}/nominee-accounts`)
    .then((resp) => createNomineeAccountPerAccountServicerData(resp.data))
    .catch((error) => createError(error));
}

/**
 * Get the matching details for nominee accounts aggregated for a particular account servicer
 * @name getNomineeMatchingDetails
 * @param nomineeUuid           the nominee uuid
 * @param accountServicerUuid   the account servicer uuid
 * @returns {Promise}           Formatted matching details
 */
export function getNomineeMatchingDetails(nomineeUuid, accountServicerUuid) {
  return axios.get(`v1/sid/nominees/${nomineeUuid}/account-servicers/${accountServicerUuid}/matching-details`)
    .then((resp) => createMatchingDetailsData(resp.data))
    .catch((error) => createError(error));
}

/**
 * Get the response accounts for matching
 * @name getResponseAccounts
 * @param sidRequestUuid   this uuid of the sid request
 * @param pageNumber       the page number
 * @param pageSize         the page size
 * @param sortBy           the key by which to sort
 * @param sortDirection    the direction in which to sort
 * @param filters          the filters
 * @returns {Promise}      Formatted matching details
 */
export async function getResponseAccounts(
  sidRequestUuid,
  pageNumber,
  pageSize,
  sortBy,
  sortDirection,
  filters,
) {
  const queryString = constructQueryParams({
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
  }, filters);
  return axios.get(`v1/sid/sid-requests/${sidRequestUuid}/unmatched-responses${queryString}`)
    .then((resp) => createResponseAccountData(resp.data))
    .catch((error) => createError(error));
}

/**
 * Get the nominee accounts for matching
 * @name getNomineeAccounts
 * @param sidRequestUuid   this uuid of the sid request
 * @param pageNumber       the page number
 * @param pageSize         the page size
 * @param sortBy           the key by which to sort
 * @param sortDirection    the direction in which to sort
 * @param filters          the filters
 * @returns {Promise}      Formatted matching details
 */
export async function getNomineeAccounts(
  sidRequestUuid,
  pageNumber,
  pageSize,
  sortBy,
  sortDirection,
  filters,
) {
  const queryString = constructQueryParams({
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
  }, filters);
  return axios.get(`v1/sid/sid-requests/${sidRequestUuid}/unmatched-nominees${queryString}`)
    .then((resp) => createNomineeAccountData(resp.data))
    .catch((error) => createError(error));
}

/**
 * Gets the matched context
 *
 * @param url the url to call
 * @param body the request body
 * @returns {Promise<T>}  the matched context
 */
export async function getMatchedContext(url, body) {
  return axios.post(url, body)
    .then((resp) => ({
      matchingResponseAccounts: createResponseAccountData({
        list: resp.data.matchingResponseAccounts,
      }),
      matchingNomineeAccounts: createNomineeAccountData({
        list: resp.data.matchingNomineeAccounts,
      }),
    }))
    .catch((error) => createError(error));
}

/**
 * Get the matched responses context for a list of nominee accounts
 * @param nomineeAccountIds the list of nominee account ids
 * @returns {Promise<T>}   the matched responses context
 */
export async function getMatchedContextNominees(
  nomineeAccountIds,
) {
  return getMatchedContext('v1/sid/matched-responses/context', {
    nomineeAccountIds,
  });
}

/**
 * Get the matched nominees context for a list of response accounts
 * @param responseAccountIds the list of response account ids
 * @returns {Promise<T>}    the matched nominees context
 */
export async function getMatchedContextResponses(
  responseAccountIds,
) {
  return getMatchedContext('v1/sid/matched-nominees/context', {
    responseAccountIds,
  });
}

/**
 * Creates a manual match between selected nominee accounts and response accounts
 *
 * @param {*} nomiAccountIds list of nominee account ids
 * @param {*} responseAccountIds list of response account ids
 * @param {*} origin 'responses' or 'nominees'
 * @returns matching response object
 */
export async function createManualMatches(nomiAccountIds, responseAccountIds, origin) {
  const body = {
    nomiAccountIds,
    responseAccountIds,
    origin,
  };
  return axios.post('v1/sid/matching', body)
    .then((resp) => resp.data)
    .catch((error) => createError(error));
}

/**
 * Retrieves the filled in preview of a disclosure email
 *
 * @param {String} sidRequestUuid the sid request uuid
 * @param {String} nomiUuid the nominee uuid
 * @param {String} accountServicerUuid the account servicer uuid
 * @returns preview email object
 */
export async function getPreviewEmail(sidRequestUuid, nomiUuid, accountServicerUuid) {
  return axios.get(`/v1/sid/sid-requests/${sidRequestUuid}/nominee-email?nomiUuid=${nomiUuid}&accountServicerUuid=${accountServicerUuid}`)
    .then((resp) => resp.data)
    .catch((error) => createError(error));
}
/**
 * Retrieves the filled in preview of a disclosure email
 *
 * @param {String} sidRequestUuid the sid request uuid
 * @param {String} nomiUuid the nominee uuid
 * @param {String} accountServicerUuid the account servicer uuid
 * @param {String} subject the subject of the email
 * @param {String} emailBody the body of the email. Is a html string
 * @param {Array} emailAddresses the list of email addresses
 * @returns {Promise<T>} email sent response object
 */
export async function sendDisclosureEmail(
  sidRequestUuid,
  nomiUuid,
  accountServicerUuid,
  subject,
  emailBody,
  emailAddresses,
) {
  const requestBody = {
    nomiUuid,
    accountServicerUuid,
    title: subject,
    body: emailBody,
    to: emailAddresses,
  };
  return axios.post(`/v1/sid/sid-requests/${sidRequestUuid}/nominee-email/send`, requestBody)
    .then((resp) => resp)
    .catch((error) => createError(error));
}

export async function setSidRequestStatus(
  sidRequestUuid,
  status,
  disapprovalReason,
) {
  const requestBody = {
    sidRequestUuid,
    status,
    disapprovalReason,
  };
  return axios.patch('/v1/sid/sid-requests', requestBody)
    .then((resp) => createSidRequestDetails(resp.data))
    .catch((error) => createError(error));
}
