/**
 * Adds work days to unix timestamp. It will skip weekend days.
 * @name addWorkDaysToUnix
 * @param unix            the unix timestamp (ms)
 * @param numberOfDays    the item id (usually rowId (which is usually the unique uuid of the item)
 * @returns {Date}        the updated array with all updated checked/unchecked items
 */
export default function addWorkDaysToUnix(unix, numberOfDays) {
  if (numberOfDays !== 0
    && (Number.isNaN(Number(unix)) || !unix
      || Number.isNaN(Number(numberOfDays)) || !numberOfDays)) {
    return null;
  }
  let i = 0;
  const newDate = new Date(unix);
  while (i < numberOfDays) {
    newDate.setDate(newDate.getDate() + 1);
    // if adding a day leads to Saturday
    if (newDate.getDay() === 6) {
      newDate.setDate(newDate.getDate() + 2);
    }
    // if adding a day leads to Sunday (if unix was a Saturday to begin with)
    if (newDate.getDay() === 0) {
      newDate.setDate(newDate.getDate() + 1);
    }
    i += 1;
  }
  return newDate;
}
