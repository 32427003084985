<template>
  <div>
    <base-tile v-if="error">
      <aab-notification
        id="error"
        type="warning"
        type-aria-label="Warning notification"
      >
        <base-typology>{{ error }}</base-typology>
      </aab-notification>
    </base-tile>
    <aab-spinner
      v-if="loading"
      size="8"
      hide-overlay
    />
    <section
      v-if="details && !loading && !error"
      :aria-label="$t('shareholderId.responseDetails')"
    >
      <base-tile
        id="title-tile"
        class="p-2 mb-2"
      >
        <div class="summary title">
          <base-typology
            tag="h3"
            variant="margin-0"
          >
            {{ $t('shareholderId.responseDetails') }}
          </base-typology>
          <base-button
            id="preview-email-button"
            :text="$t('utils.previewSendEmail')"
            style-type="primary"
            icon="sy_tools_messagesent"
            @click="openEmailModal"
            @keyup.enter="openEmailModal"
          />
        </div>
      </base-tile>
      <base-tile
        id="summary-tile"
        class="p-2 mb-2"
      >
        <div class="summary">
          <div
            id="total-account-servicer"
            class="summary-item number"
          >
            <base-typology
              class="pb-1"
              tag="label"
            >
              {{ $t('gen.total', { value: $t('shareholderId.accountServicer').toLowerCase() }) }}
            </base-typology>
            <base-typology
              variant="intro"
            >
              {{ formatNumber(details.totalBalance.totalBalanceByAccountServicer, 'decimal') }}
            </base-typology>
          </div>
          <div
            id="total-responder"
            class="summary-item number"
          >
            <base-typology
              class="pb-1"
              tag="label"
            >
              {{ $t('gen.total', { value: $t('shareholderId.responder').toLowerCase() }) }}
            </base-typology>
            <base-typology
              variant="intro"
              class="summary-value"
            >
              {{ formatNumber(details.totalBalance.totalBalanceByNominee, 'decimal') }}
            </base-typology>
          </div>
          <div
            id="progress-bar"
            class="summary-item progress"
          >
            <base-typology
              class="pb-1"
              tag="label"
            >
              {{ $t('shareholderId.responseRate') }}
            </base-typology>
            <aab-progress-bar
              background-color="grey"
              progress-max="100"
              textual-progress-aria-label="Textual representation of progressbar"
              visual-progress-aria-label="Visual representation of progressbar"
              :textual-progress-value="formatNumber(totalProgress, 'percent')"
              :visual-progress-value="totalProgress * 100"
            />
          </div>
          <div
            id="total-match-quality"
            class="summary-item"
          >
            <base-typology
              class="pb-1"
              tag="label"
            >
              {{ $t('gen.total', { value: $t('shareholderId.quality').toLowerCase() }) }}
            </base-typology>
            <aab-status
              class="summary-value"
              :status-message="details.totalBalance.matchQuality.statusMessage"
              :style-type="details.totalBalance.matchQuality.styleType"
              has-background
            />
          </div>
          <div
            id="last-email-sent"
            class="summary-item"
          >
            <base-typology
              class="pb-1"
              tag="label"
            >
              {{ $t('utils.lastEmailSent') }}
            </base-typology>
            <base-typology>
              {{ details.totalBalance.lastEmailSentBy }}
            </base-typology>
            <base-typology
              variant="small"
              color="gray"
            >
              {{ details.totalBalance.lastEmailSentOn }}
            </base-typology>
          </div>
        </div>
      </base-tile>
      <base-tile
        v-for="(nomination, index) in details.nominations"
        :id="`nomination-${index}`"
        :key="index"
        class="p-2 mb-2"
      >
        <matching-details-content :nomination="nomination"></matching-details-content>
      </base-tile>
    </section>
    <section>
      <disclosure-email-modal
        :open-disclosure-email-modal="openDisclosureEmailModal"
        @close-email-modal="openDisclosureEmailModal = null"
        @email-sent="confirmEmailSent"
      />
    </section>
    <aab-toastmessage
      id="confirm-sent-toast"
      :toast-message-text="$t('shareholderId.disclosureEmailSent')"
    ></aab-toastmessage>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { getNomineeMatchingDetails } from '@/services/sidService';
import { formatNumber } from '@/utils/formatLib';
import useSidRequestStore from '@/stores/SidRequestStore';
import BaseTile from '@/components/base/BaseTile.vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import MatchingDetailsContent from '@/components/shareholderId/nominees/MatchingDetailsNomination.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import DisclosureEmailModal from '@/components/shareholderId/nominees/DisclosureEmailModal.vue';
import '@aab/sc-aab-toastmessage';

export default defineComponent({
  name: 'MatchingDetails',
  components: {
    DisclosureEmailModal,
    BaseButton,
    MatchingDetailsContent,
    BaseTypology,
    BaseTile,
  },
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
  data() {
    return {
      loading: false,
      error: null,
      details: null,
      openDisclosureEmailModal: null,
    };
  },
  computed: {
    totalProgress() {
      if (this.details) {
        const { totalBalance } = this.details;
        if (totalBalance.totalBalanceByAccountServicer !== 0
          && totalBalance.totalBalanceByAccountServicer) {
          return totalBalance.totalBalanceByNominee / totalBalance.totalBalanceByAccountServicer;
        }
      }
      return 0;
    },
  },
  async created() {
    await this.getMatchingDetails();
    if (this.details) {
      const { detailsByNominee } = this.details.nominations[0].matching.identifiers;
      const { detailsByAccountServicer } = this.details.nominations[0].matching.identifiers;
      const name = detailsByNominee.nomineeName || detailsByAccountServicer.nomineeName;
      this.sidRequestStore.setNomineeAccountName(name);
    }
  },
  unmounted() {
    this.sidRequestStore.clearNomineeAccountName();
  },
  methods: {
    formatNumber,
    async getMatchingDetails() {
      this.loading = true;
      this.error = null;
      this.details = null;
      try {
        this.details = await getNomineeMatchingDetails(
          this.$route.params.nomineeUuid,
          this.$route.params.accountServicerUuid,
        );
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.getMatchingDetails')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
    openEmailModal() {
      this.openDisclosureEmailModal = true;
    },
    async confirmEmailSent() {
      this.openDisclosureEmailModal = null;
      this.$el.querySelector('aab-toastmessage').show();
      await this.getMatchingDetails();
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles' as lib;

@include lib.d_flex;
@include lib.justify_content_between;
@include lib.pb_1;
@include lib.pb_2;
@include lib.p_2;
@include lib.mb_2;

.summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  &.title {
    align-items: center;
  }
}

.summary-item {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  &.progress {
    flex-grow: 1;
  }
  &.number {
    align-items: flex-end;
  }
}

</style>
