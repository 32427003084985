<template>
  <section
    class="form-section"
    :aria-label="$t('utils.formSection', { value: $t('gen.shares') })"
  >
    <base-typology tag="h3">
      {{ $t('gen.shares') }}
    </base-typology>
    <div class="input-field">
      <aab-input
        id="total-outstanding-shares"
        :message="totalOutstandingSharesValidationMessage"
        :message-type="totalOutstandingSharesValidationMessage ? 'negative' : null"
      >
        <label
          slot="label"
          for="total-outstanding-shares"
        >
          {{ $t('gen.total', { value: $t('shareholderId.outstandingShares').toLowerCase()}) }}
        </label>
        <input
          v-model="viewTotalOutstandingShares"
          v-thousand-separator
          name="total-outstanding-shares"
          maxlength="13"
          type="text"
          @blur="onBlurTotalOutstandingShares"
        >
      </aab-input>
    </div>
    <div>
      <div class="mb-1">
        <base-typology tag="label">
          {{ $t('shareholderId.threshold') }}
        </base-typology>
      </div>
      <div class="radio-button-group">
        <aab-radio-button
          id="percentage-radio"
          @click="updateThreshold('percentage')"
          @keyup.enter="updateThreshold('percentage')"
        >
          <input
            id="percentage-input"
            slot="input-slot"
            name="threshold-percentage"
            type="radio"
            :checked="thresholdUnit === 'percentage'"
          >
          <label
            slot="label-slot"
            for="threshold-percentage"
            class="text-center"
          >
            {{ $t('gen.percentage') }}
          </label>
        </aab-radio-button>
        <aab-radio-button
          id="quantity-radio"
          @click="updateThreshold('quantity')"
          @keyup.enter="updateThreshold('quantity')"
        >
          <input
            id="quantity-input"
            slot="input-slot"
            name="threshold-quantity"
            type="radio"
            :checked="thresholdUnit === 'quantity'"
          >
          <label
            slot="label-slot"
            for="threshold-quantity"
            class="text-center"
          >
            {{ $t('gen.quantity') }}
          </label>
        </aab-radio-button>
        <aab-radio-button
          id="min-quantity-radio"
          @click="updateThreshold('minQuantity')"
          @keyup.enter="updateThreshold('minQuantity')"
        >
          <input
            id="min-quantity-input"
            slot="input-slot"
            name="threshold-min-quantity"
            type="radio"
            :checked="thresholdUnit === 'minQuantity'"
          >
          <label
            slot="label-slot"
            for="threshold-min-quantity"
            class="text-center"
          >
            {{ $t('gen.minimumValue') }}
          </label>
        </aab-radio-button>
      </div>
    </div>
    <div v-if="thresholdUnit === 'percentage'">
      <div class="percentage-input">
        <aab-input
          v-if="thresholdUnit === 'percentage'"
          id="threshold-input-percentage"
          :message="thresholdValidationMessage"
          :message-type="thresholdValidationMessage ? 'negative' : null"
        >
          <label
            slot="label"
            for="threshold"
          >
            {{ $t('gen.percentage') }}
          </label>
          <input
            v-model="thresholdPercentage"
            name="threshold"
            type="number"
            step="0.1"
            @blur="onBlurPercentage"
          >
        </aab-input>
      </div>
      <div
        v-if="calculatedThreshold"
        id="calculated-threshold"
        class="mt-1"
      >
        <base-typology
          variant="small"
          color="gray"
        >
          {{
            $t('shareholderId.calThreshold',
               { value: formatNumber(calculatedThreshold, 'decimal') })
          }}
        </base-typology>
      </div>
    </div>
    <div
      v-if="thresholdUnit === 'quantity'"
      class="input-field"
    >
      <aab-input
        v-if="thresholdUnit === 'quantity'"
        id="threshold-input-quantity"
        :message="thresholdValidationMessage"
        :message-type="thresholdValidationMessage ? 'negative' : null"
      >
        <label
          slot="label"
          for="threshold"
        >
          {{ $t('gen.quantity') }}
        </label>
        <input
          v-model="viewThreshold"
          v-thousand-separator
          name="threshold"
          type="text"
          @blur="onBlurThreshold"
        >
      </aab-input>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import { maxValue, minValue, requiredEnterValidValue } from '@/utils/customValidation';
import { helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { formatNumber } from '@/utils/formatLib';
import createValidationMessage from '@/utils/validationUtils';

/**
 * FormShares is a section of the SID Create form.
 * User fills in the total outstanding shares and the threshold. Threshold is
 * calculated either in absolute amount of shares or percentage of total outstanding shares.
 * Validation rules for all fields are specified at the bottom.
 */
export default defineComponent({
  name: 'FormShares',
  components: { BaseTypology },
  data() {
    return {
      v$: useVuelidate(),
      thresholdUnit: 'percentage',
      thresholdPercentage: null,
      viewTotalOutstandingShares: null,
      viewThreshold: null,
      form: {
        totalOutstandingShares: null,
        thresholdUnit: 'percentage',
        threshold: null,
      },
    };
  },
  computed: {
    thresholdValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.form, 'threshold');
    },
    totalOutstandingSharesValidationMessage() {
      return createValidationMessage(this.v$.$errors, this.v$.form, 'totalOutstandingShares');
    },
    calculatedThreshold() {
      if (this.form.totalOutstandingShares && this.thresholdPercentage
        && this.form.thresholdUnit === 'percentage') {
        return this.form.totalOutstandingShares * (this.thresholdPercentage / 100);
      }
      return null;
    },
  },
  methods: {
    formatNumber,
    updateThreshold(thresholdType) {
      if (this.thresholdUnit === thresholdType) {
        return;
      }
      this.v$.form.threshold.$reset();
      this.thresholdUnit = thresholdType;
      this.thresholdPercentage = null;
      if (thresholdType === 'minQuantity') {
        this.form.thresholdUnit = 'quantity';
        this.form.threshold = 1;
      } else {
        this.form.thresholdUnit = thresholdType;
        this.form.threshold = null;
      }
    },
    onBlurTotalOutstandingShares() {
      const number = Number.parseInt(this.viewTotalOutstandingShares?.replace(/,/g, ''), 10);
      if (Number.isNaN(number) || this.viewTotalOutstandingShares === '') {
        this.form.totalOutstandingShares = null;
      } else {
        this.form.totalOutstandingShares = number;
      }
      this.v$.form.totalOutstandingShares.$touch();
    },
    onBlurPercentage() {
      this.form.threshold = this.thresholdPercentage;
      this.v$.form.threshold.$touch();
    },
    onBlurThreshold() {
      const number = Number.parseInt(this.viewThreshold?.replace(/,/g, ''), 10);
      if (Number.isNaN(number)) {
        this.form.threshold = null;
      } else {
        this.form.threshold = number;
      }
      this.v$.form.threshold.$touch();
    },
  },
  validations() {
    const thresholdParam = this.thresholdUnit === 'percentage' ? 'gen.percentage' : 'gen.number';
    let maxValueValidatorThreshold;
    if (this.thresholdUnit === 'percentage') {
      maxValueValidatorThreshold = {
        maxValue: helpers.withParams(
          { field: this.$t(thresholdParam), limitField: this.$t('gen.limit').toLowerCase(), value: '4%' },
          maxValue(4),
        ),
        minValue: helpers.withParams(
          { field: this.$t(thresholdParam), limitField: this.$t('gen.limit').toLowerCase(), value: '0.01%' },
          minValue(0.01),
        ),
      };
    } else {
      maxValueValidatorThreshold = {
        maxValue: helpers.withParams(
          {
            field: this.$t(thresholdParam),
            limitField: this.$t('gen.total', { value: this.$t('shareholderId.outstandingShares') }).toLowerCase(),
            value: formatNumber(this.form.totalOutstandingShares, 'decimal'),
          },
          maxValue(this.form.totalOutstandingShares),
        ),
        minValue: helpers.withParams(
          { field: this.$t(thresholdParam), limitField: this.$t('gen.limit').toLowerCase(), value: 1 },
          minValue(1),
        ),
      };
    }
    return {
      form: {
        totalOutstandingShares: {
          requiredEnterValidValue: helpers.withParams(
            { field: this.$t('gen.number').toLowerCase() },
            requiredEnterValidValue,
          ),
        },
        threshold: {
          requiredEnterValidValue: helpers.withParams(
            { field: this.$t(thresholdParam).toLowerCase() },
            requiredEnterValidValue,
          ),
          ...maxValueValidatorThreshold,
        },
      },
    };
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles' as lib;

@include lib.mb_1;
@include lib.mt_1;

.percentage-input {
  width: 90px;
}

</style>
