export default {
  sidHome: {
    routeName: 'Shareholder ID',
    i18nKey: 'shareholderId.label',
    route: '/sid',
    requiresAuth: true,
    list: {
      route: 'list',
      routeName: 'SID Request List',
      requiresAuth: true,
    },
    create: {
      route: 'create',
      routeName: 'SID Request Creation',
      requiresAuth: true,
    },
    sidRequest: {
      route: 'request/:sidRequestUuid',
      routeName: 'SID Request',
      requiresAuth: true,
      details: {
        routeName: 'SID request details',
        i18nKey: 'shareholderId.details',
        route: 'details',
      },
      report: {
        routeName: 'SID request report',
        i18nKey: 'shareholderId.report',
        route: 'report',
      },
      nominees: {
        routeName: 'SID request nominees',
        i18nKey: 'shareholderId.nominee',
        route: 'nominees',
      },
      matchingDetails: {
        routeName: 'SID matching details',
        i18nKey: 'shareholderId.matchingDetails',
        route: ':nomineeUuid/:accountServicerUuid',
      },
      manualMatching: {
        routeName: 'SID request manual matching',
        i18nKey: 'shareholderId.manualMatching',
        route: 'manual-matching',
      },
    },
  },
  userManagement: {
    routeName: 'Users',
    i18nKey: 'user-management.label',
    route: '/users',
    requiresAuth: true,
    users: {
      routeName: 'UserOverview',
      i18nKey: 'user-management.userOverview',
      route: 'overview',
    },
    addUser: {
      routeName: 'AddUser',
      i18nKey: 'user-management.addUser',
      route: 'add-user',
    },
  },
  account: {
    routeName: 'Account',
    i18nKey: 'account.label',
    route: '/account',
    requiresAuth: true,
    myAccount: {
      routeName: 'MyAccount',
      i18nKey: 'account.myAccount.label',
      route: 'my-account',
    },
    changePassword: {
      routeName: 'ChangePassword',
      i18nKey: 'account.changePassword.label',
      route: 'change-password',
    },
  },
};
