import LoginView from '@/views/LoginView.vue';
import navItems from '@/utils/reference/navItems';
import UsersView from '@/views/UsersView.vue';
import UserList from '@/components/user/UserList.vue';
import UserForm from '@/components/user/UserForm.vue';
import UserAccountView from '@/views/UserAccountView.vue';
import AccountDetails from '@/components/user/AccountDetails.vue';
import ChangePassword from '@/components/user/ChangePassword.vue';
import SidHomeView from '@/views/sid/SidHomeView.vue';
import SidRequestView from '@/views/sid/SidRequestView.vue';
import RequestDetails from '@/components/shareholderId/sidRequest/RequestDetails.vue';
import RequestNominees from '@/components/shareholderId/sidRequest/RequestNominees.vue';
import RequestReport from '@/components/shareholderId/sidRequest/RequestReport.vue';
import RequestMatching from '@/components/shareholderId/sidRequest/RequestMatching.vue';
import MatchingDetails from '@/components/shareholderId/nominees/MatchingDetails.vue';
import SidCreateView from '@/views/sid/SidCreateView.vue';
import SidListView from '@/views/sid/SidListView.vue';

export default [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    meta: { requiresNonAuth: true },
    props: { action: 'login' },
  },
  {
    path: navItems.sidHome.route,
    name: navItems.sidHome.routeName,
    component: SidHomeView,
    redirect: '/sid/list',
    meta: {
      requiresAuth: navItems.sidHome.requiresAuth,
    },
    children: [
      {
        path: navItems.sidHome.list.route,
        name: navItems.sidHome.list.routeName,
        component: SidListView,
      },
      {
        path: navItems.sidHome.create.route,
        name: navItems.sidHome.create.routeName,
        component: SidCreateView,
        meta: { navBarInvisible: true },
      },
      {
        path: navItems.sidHome.sidRequest.route,
        name: navItems.sidHome.sidRequest.routeName,
        component: SidRequestView,
        redirect: '/request/:sidRequestUuid/details',
        props: true,
        children: [
          {
            path: navItems.sidHome.sidRequest.details.route,
            name: navItems.sidHome.sidRequest.details.routeName,
            component: RequestDetails,
          },
          {
            path: navItems.sidHome.sidRequest.report.route,
            name: navItems.sidHome.sidRequest.report.routeName,
            component: RequestReport,
          },
          {
            path: navItems.sidHome.sidRequest.nominees.route,
            name: navItems.sidHome.sidRequest.nominees.routeName,
            component: RequestNominees,
          },
          {
            path: navItems.sidHome.sidRequest.matchingDetails.route,
            name: navItems.sidHome.sidRequest.matchingDetails.routeName,
            component: MatchingDetails,
          },
          {
            path: navItems.sidHome.sidRequest.manualMatching.route,
            name: navItems.sidHome.sidRequest.manualMatching.routeName,
            component: RequestMatching,
          },
        ],
      },
    ],
  },
  {
    path: navItems.userManagement.route,
    name: navItems.userManagement.routeName,
    component: UsersView,
    redirect: '/users/overview',
    meta: {
      requiresAuth: navItems.userManagement.requiresAuth,
    },
    children: [
      {
        path: navItems.userManagement.users.route,
        name: navItems.userManagement.users.routeName,
        component: UserList,
      },
      {
        path: navItems.userManagement.addUser.route,
        name: navItems.userManagement.addUser.routeName,
        component: UserForm,
      },
    ],
  },
  {
    path: navItems.account.route,
    name: navItems.account.routeName,
    component: UserAccountView,
    redirect: '/account/my-account',
    meta: {
      requiresAuth: navItems.account.requiresAuth,
    },
    children: [
      {
        path: navItems.account.myAccount.route,
        name: navItems.account.myAccount.routeName,
        component: AccountDetails,
      },
      {
        path: navItems.account.changePassword.route,
        name: navItems.account.changePassword.routeName,
        component: ChangePassword,
      },
    ],
  },
  {
    path: '/activate-account',
    name: 'ActivateAccountView',
    component: LoginView,
    meta: { requiresNonAuth: true },
    props: { action: 'activate' },
  },
  {
    path: '/forget-password',
    name: 'ForgetPasswordView',
    component: LoginView,
    meta: { requiresNonAuth: true },
    props: { action: 'forgotten' },
  },
  {
    // must be last as fallback
    path: '/:pathMatch(.*)*',
    redirect: '/login',
  },
];
