<template>
  <div>
    <section
      id="unmatched-data"
      :aria-label="$tc('shareholderId.response', 2)"
    >
      <base-data
        :columns="columns"
        :rows="responses"
        :title="`${$tc('shareholderId.response', 2)} (${totalResponses})`"
        selectable-rows
        :loading="loading"
        :error="error"
        :empty-message="$t('shareholderId.noResults',
                           { data: $tc('shareholderId.response', 2).toLowerCase() }
        )"
        :sort-direction="sortDirection"
        :sort-on="sortBy"
        :page-size="pageSize"
        :page-number="pageNumber"
        :total-items="totalResponses"
        :action-bar="actionBar"
        @row-selected="responseSelected($event)"
        @request-update="updateResponseAccounts($event)"
        @action-bar-event="processActionBarEvent($event)"
      />
    </section>
    <matching-filters
      :open-modal="openModal"
      @close-filters="openModal = null"
      @apply-filters="updateResponseAccounts($event)"
      @reset-filters="resetFilters"
    />
    <manual-matching
      origin="responses"
      :pre-selected-items="selectedResponses"
      :open-matching-modal="openMatchingModal"
      @close-manual-matching="openMatchingModal = null"
      @manual-matches-created="handleManualMatchesCreated"
    />
    <aab-toastmessage
      :toast-message-text="$t('shareholderId.manualMatchCreated')"
    ></aab-toastmessage>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { getResponseAccounts } from '@/services/sidService';
import BaseData from '@/components/base/BaseData.vue';
import MatchingFilters from '@/components/shareholderId/matching/MatchingFilters.vue';
import useSidRequestStore from '@/stores/SidRequestStore';
import ManualMatching from '@/components/shareholderId/matching/ManualMatching.vue';
import {
  accountServicerNameConverter,
  amountConverter,
  responderNameConverter,
  matchedAmountConverter,
} from '@/utils/modelConverters/baseDataTableConverters';
import {
  provideNewArrayWithSelectedItemsAfterItemClick,
  provideUpdatedArrayAfterItemClick,
} from '@/utils/baseDataHelpers';
import '@aab/sc-aab-toastmessage';
import { lowerCaseFirstChar } from '@/utils/formatLib';

export default defineComponent({
  name: 'MatchResponses',
  components: { ManualMatching, MatchingFilters, BaseData },
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
  data() {
    return {
      loading: false,
      error: null,
      responses: [],
      selectedResponses: [],
      idsOfSelectedRows: [],
      totalResponses: 0,
      pageSize: 25,
      pageNumber: 1,
      sortBy: 'amount',
      sortDirection: 'DESC',
      openModal: null,
      openMatchingModal: null,
      numberOfFiltersActive: 0,
      filters: {
        searchBy: null,
        amountFrom: null,
        amountTo: null,
        matchedAmountCategories: ['UNMATCHED', 'PARTIAL_MATCH'],
      },
    };
  },
  computed: {
    actionBar() {
      return {
        secondaryAction: {
          icon: 'sy_arrow_change',
          text: this.$t('utils.switchTo', { value: this.$tc('shareholderId.nominee', 2).toLowerCase() }),
          event: 'switch-to-nominees',
          disabled: false,
        },
        primaryAction: {
          text: this.$t('shareholderId.findMatch'),
          event: 'match',
        },
        search: {
          searchOptionsKey: 'shareholderId.matchingSearchOptions',
          type: 'input',
        },
        filters: {
          setFilters: true,
          text: `${this.$t('utils.filters')} (${this.numberOfFiltersActive})`,
        },
      };
    },
    columns() {
      return [
        {
          key: 'checked',
          template: 'checkbox',
          sortable: false,
          selectAll: false,
          width: '5',
        },
        {
          key: 'responderName',
          template: 'title',
          label: this.$t('shareholderId.responder'),
          sortable: true,
          width: '30',
        },
        {
          key: 'accountServicerName',
          template: 'title',
          label: this.$t('shareholderId.accountServicer'),
          sortable: true,
          width: '25',
        },
        {
          key: 'accountNumber',
          label: this.$tc('gen.account', 1),
          sortable: true,
          width: '10',
        },
        {
          key: 'amount',
          template: 'amount',
          label: this.$t('gen.amount'),
          sortable: true,
          width: '15',
        },
        {
          key: 'matchedAmountCategory',
          template: 'status',
          label: this.$t('utils.status'),
          sortable: true,
          width: '15',
        },
      ];
    },
  },
  async created() {
    this.emptyMessage = this.$t('shareholderId.noResults', { data: lowerCaseFirstChar(this.$tc('shareholderId.response', 2)) });
    await this.getResponseAccountsForMatching();
    this.updateNumberOfFiltersActive();
  },
  methods: {
    async getResponseAccountsForMatching() {
      this.loading = true;
      this.error = null;
      try {
        const data = await getResponseAccounts(
          this.$route.params.sidRequestUuid,
          this.pageNumber,
          this.pageSize,
          this.sortBy,
          this.sortDirection,
          this.filters,
        );
        this.responses = Object.keys(data.responseAccounts)
          .map((key) => ({
            id: data.responseAccounts[key].responseAccountUuid,
            checked: this.idsOfSelectedRows
              .includes(data.responseAccounts[key].responseAccountUuid),
            disabled: false,
            responderName: responderNameConverter(data.responseAccounts[key]),
            accountServicerName: accountServicerNameConverter(data.responseAccounts[key]),
            accountNumber: data.responseAccounts[key].accountNumber,
            amount: amountConverter(data.responseAccounts[key].amount, 'decimal'),
            matchedAmountCategory: matchedAmountConverter(
              data.responseAccounts[key].matchedAmountCategory,
            ),
          }));
        this.responses = this.updateResponsesDisabled();
        this.totalResponses = data.totalItems;
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.getResponseAccounts')} ${e.code}: ${this.$t(e.messageKey)}`;
      } finally {
        this.loading = false;
      }
    },
    async updateResponseAccounts(event) {
      this.pageSize = event.pageSize ? event.pageSize : this.pageSize;
      this.pageNumber = event.pageNumber ? event.pageNumber : this.pageNumber;
      this.sortBy = event.sortOn ? event.sortOn : this.sortBy;
      this.sortDirection = event.sortDir ? event.sortDir : this.sortDirection;
      this.filters.amountFrom = event.amountFrom ? event.amountFrom : this.filters.amountFrom;
      this.filters.amountTo = event.amountTo ? event.amountTo : this.filters.amountTo;
      const matchedCategories = [];
      // this.filters.matchedAmountCategories = [];
      if (event.unmatched) {
        matchedCategories.push('UNMATCHED');
      }
      if (event.partialMatched) {
        matchedCategories.push('PARTIAL_MATCH');
      }
      if (event.matched) {
        matchedCategories.push('MATCHED');
      }
      this.filters.matchedAmountCategories = matchedCategories.length > 0 ? matchedCategories
        : this.filters.matchedAmountCategories;
      this.updateNumberOfFiltersActive();
      await this.getResponseAccountsForMatching();
    },
    async resetFilters() {
      this.sortBy = 'amount';
      this.sortDirection = 'DESC';
      this.filters.amountFrom = null;
      this.filters.amountTo = null;
      this.filters.matchedAmountCategories = ['UNMATCHED', 'PARTIAL_MATCH'];
      this.updateNumberOfFiltersActive();
      await this.getResponseAccountsForMatching();
    },
    async processActionBarEvent(event) {
      if (event.event === 'search') {
        this.pageNumber = 1;
        this.filters.searchBy = event.value;
        await this.getResponseAccountsForMatching();
      } else if (event.event === 'open-filters') {
        this.openModal = true;
      } else if (event.event === 'switch-to-nominees') {
        this.sidRequestStore.switchRequestMatchingPage();
      } else if (event.event === 'match') {
        this.openMatchingModal = true;
      } else {
        // warning for developer - does not need translating
        console.warn(`Action bar generated unknown event: ${event.event}`);
      }
    },
    updateNumberOfFiltersActive() {
      let totalAmount = this.filters.matchedAmountCategories.length;
      if (this.filters.amountFrom > 0) {
        totalAmount += 1;
      }
      if (this.filters.amountTo > 0) {
        totalAmount += 1;
      }
      this.numberOfFiltersActive = totalAmount;
    },
    responseSelected(event) {
      this.responses = provideUpdatedArrayAfterItemClick(this.responses, event.rowId);
      this.selectedResponses = provideNewArrayWithSelectedItemsAfterItemClick(
        this.responses,
        this.selectedResponses,
        event.rowId,
      );
      this.idsOfSelectedRows = this.selectedResponses.map((row) => row.id);
      this.responses = this.updateResponsesDisabled();
    },
    updateResponsesDisabled() {
      const selectedCategories = this.selectedResponses
        .map((row) => row.matchedAmountCategory.value);
      // only disable rows when there are previously matched selected rows
      if (selectedCategories.includes('PARTIAL_MATCH') || selectedCategories.includes('MATCHED')) {
        return this.responses.map((item) => {
          const newItem = item;
          // do not disable any of the selected items
          if (this.idsOfSelectedRows.includes(item.id)) {
            return newItem;
          }
          // disable rows that are already previously matched
          if (item.matchedAmountCategory.value !== 'UNMATCHED') {
            newItem.disabled = true;
            return newItem;
          }
          return newItem;
        });
      }
      return this.responses.map((item) => {
        const newItem = item;
        newItem.disabled = false;
        return newItem;
      });
    },
    /**
     * When manual matching is successful we can close the modal and
     * refresh the list of unmatched responses
     */
    handleManualMatchesCreated() {
      this.openMatchingModal = null;
      this.selectedResponses = [];
      this.idsOfSelectedRows = [];
      this.$el.querySelector('aab-toastmessage').show();
      this.getResponseAccountsForMatching();
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles' as lib;

@include lib.align_items_center;
@include lib.d_flex;
tr {
  &:hover {
    cursor: pointer;
    background-color: lib.$g50;
  }
  td {
    border-bottom: 1px solid lib.$n65;
    padding: 0.75rem;
    vertical-align: top;
  }
}

.center-align {
  text-align: center;
  vertical-align: middle;
}

.inline {
  display: inline;
}

.right-align {
  text-align: right;
}
</style>
