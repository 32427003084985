<template>
  <div>
    <section
      id="buttons-tile"
      :aria-label="$t('shareholderId.sidDetailsButtonsTile')"
    >
      <base-tile
        id="title-tile"
        class="p-3 mb-2"
      >
        <div class="header-tile title">
          <base-typology
            tag="h3"
            variant="margin-0"
          >
            Details
          </base-typology>
          <div v-if="getButton()">
            <base-button
              id="sid-request-button"
              icon="sy_form_valid"
              size="3"
              label="remove row from table"
              :text="getButton().buttonText"
              style-type="primary"
              @click="onClickButton(getButton().onClickValue)"
              @keyup.enter="onClickButton(getButton().onClickValue);"
            />
          </div>
        </div>
      </base-tile>
    </section>
    <div
      v-if="error"
      id="error"
      class="m-3"
    >
      <aab-notification
        type="negative"
        type-aria-label="Error notification"
      >
        <base-typology>{{ error }}</base-typology>
      </aab-notification>
    </div>
  </div>
  <base-tile class="mt-3 bg-white">
    <div class="p-3">
      <section
        v-if="sidRequest"
        aria-label="SID request details"
      >
        <div id="issuerName">
          <base-typology tag="label">
            {{ $t('issuer.issuer') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ sidRequest.issuerName }}
          </base-typology>
        </div>
        <div id="isin">
          <base-typology tag="label">
            {{ $t('shareholderId.isin') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ sidRequest.isin }}
          </base-typology>
        </div>
        <div
          id="status"
          class="pb-2"
        >
          <base-typology tag="label">
            {{ $t('utils.status') }}
          </base-typology><br>
          <aab-status
            :status-message="sidRequest.status.statusMessage"
            :style-type="sidRequest.status.styleType"
            has-background
          />
        </div>
        <div id="csd">
          <base-typology tag="label">
            {{ $t('shareholderId.csd') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ sidRequest.csd }}
          </base-typology>
        </div>
        <div id="threshold">
          <base-typology tag="label">
            {{ $t('shareholderId.threshold') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ formatNumber(sidRequest.threshold, 'decimal') }}
          </base-typology>
        </div>
        <div id="totalOutstandingSharesCsd">
          <base-typology tag="label">
            {{ $t('gen.total', {
              value: lowerCaseFirstChar($t('shareholderId.outstandingSharesCsd'))
            }) }}
          </base-typology><br>
          <base-typology tag="p">
            {{ formatNumber(sidRequest.totalOutstandingSharesCsd, 'decimal') }}
          </base-typology>
        </div>
        <div id="totalOutstandingShares">
          <base-typology tag="label">
            {{ $t('gen.total',
                  { value: $t('shareholderId.outstandingShares').toLowerCase() }) }}
          </base-typology><br>
          <base-typology tag="p">
            {{ formatNumber(sidRequest.totalOutstandingShares, 'decimal') }}
          </base-typology>
        </div>
        <div id="recordDate">
          <base-typology tag="label">
            {{ $t('shareholderId.recordDate') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ sidRequest.recordDate }}
          </base-typology>
        </div>
        <div id="startDate">
          <base-typology tag="label">
            {{ $t('shareholderId.startDate') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ sidRequest.startDate }}
          </base-typology>
        </div>
        <div id="disclosureDeadlineDate">
          <base-typology tag="label">
            {{ $t('shareholderId.disclosureDeadlineDate') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ sidRequest.disclosureDeadlineDate }}
          </base-typology>
        </div>
        <div id="reportDeadlineDate">
          <base-typology tag="label">
            {{ $t('shareholderId.reportDeadlineDate') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ sidRequest.reportDeadlineDate }}
          </base-typology>
        </div>
        <div id="scheduledPubDate">
          <base-typology tag="label">
            {{ $t('shareholderId.scheduledPubDate') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ sidRequest.scheduledPubDate }}
          </base-typology>
        </div>
        <div id="pubSentDate">
          <base-typology tag="label">
            {{ $t('shareholderId.pubSentDate') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ sidRequest.pubSentDate }}
          </base-typology>
        </div>
        <div id="creationDate">
          <base-typology tag="label">
            {{ $t('shareholderId.creationDate') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ sidRequest.createdDate }}
            {{ $t('shareholderId.actionByName',
                  { action: 'created', name: sidRequest.createdBy}) }}
          </base-typology>
        </div>
        <div
          v-if="sidRequest.approvalDate"
          id="approvalDate"
        >
          <base-typology tag="label">
            {{ $t('shareholderId.approvalDate') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ sidRequest.approvalDate }}
            {{ $t('shareholderId.actionByName',
                  { action: 'approved', name: sidRequest.approvedBy}) }}
          </base-typology>
        </div>
        <div
          v-if="sidRequest.cancelledDate"
          id="cancelledDate"
        >
          <base-typology tag="label">
            {{ $t('shareholderId.cancelledDate') }}
          </base-typology><br>
          <base-typology tag="p">
            {{ sidRequest.cancelledDate }}
            {{ $t('shareholderId.actionByName',
                  { action: 'cancelled', name: sidRequest.cancelledBy}) }}
          </base-typology>
        </div>
      </section>
    </div>
  </base-tile>
</template>

<script>
import { defineComponent } from 'vue';
import BaseTile from '@/components/base/BaseTile.vue';
import BaseTypology from '@/components/base/BaseTypology.vue';
import useSidRequestStore from '@/stores/SidRequestStore';
import { formatNumber, lowerCaseFirstChar } from '@/utils/formatLib';
import BaseButton from '@/components/base/BaseButton.vue';
import { setSidRequestStatus } from '@/services/sidService';
import { generalStatus } from '@/utils/reference/globalConstants';

export default defineComponent({
  name: 'RequestDetails',
  components: {
    BaseButton,
    BaseTypology,
    BaseTile,
  },
  setup() {
    const sidRequestStore = useSidRequestStore();
    return { sidRequestStore };
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    sidRequest() {
      return this.sidRequestStore.getSidRequest;
    },
  },
  methods: {
    formatNumber,
    lowerCaseFirstChar,
    getButton() {
      const buttonInfo = 'utils.sidRequestButton';
      if (this.sidRequest.status.value === generalStatus.agentApproval) {
        return {
          buttonText: this.$t(buttonInfo.concat('.agent')),
          onClickValue: generalStatus.approved,
        };
      }
      if (this.sidRequest.status.value === generalStatus.issuerApproval) {
        return {
          buttonText: this.$t(buttonInfo.concat('.issuer')),
          onClickValue: generalStatus.approved,
        };
      }
      if (this.sidRequest.status.value === generalStatus.inProgress) {
        return {
          buttonText: this.$t(buttonInfo.concat('.complete')),
          onClickValue: generalStatus.completed,
        };
      }
      return null;
    },
    async refreshPage(newSidRequestData) {
      await this.sidRequestStore.setSidRequestDetails(newSidRequestData);
    },
    async onClickButton(status) {
      try {
        const requestResponse = await setSidRequestStatus(
          this.sidRequestStore.getSidUuid,
          status,
          '',
        );
        await this.refreshPage(requestResponse);
      } catch (e) {
        this.error = `${this.$t('shareholderId.errors.disclosureApprovalError')} ${e.code}: ${this.$t(e.messageKey)}`;
      }
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/styles/styles.scss' as lib;

@include lib.p_3;
@include lib.pb_2;
@include lib.mt_3;

.header-tile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  &.title {
    align-items: center;
  }
}

</style>
